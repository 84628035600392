<template>
  <div class="calendarData">
    <div>
      <inputText
        :data="name"
        :label="$t('settings.contact.name.title')"
        :help="$t('settings.contact.name.info')"
        :callback="setName"
        :styling="{ input: 'width: 500px; max-width: 100%; display: block' }"
        :placeholder="$t('settings.contact.name.title')"
      />
    </div>
    <div>
      <inputEmail
        :data="email"
        :label="$t('settings.contact.email.title')"
        :help="$t('settings.contact.email.info')"
        :callback="setEmail"
        :styling="{ input: 'width: 500px; max-width: 100%; display: block' }"
        :placeholder="$t('settings.contact.email.title')"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      name: this.$store.state.business.unsaved.contactName,
      email: this.$store.state.business.unsaved.contactEmail
    };
  },
  methods: {
    setName(val) {
      this.name = val;
      this.setSave();
    },
    setEmail(val) {
      this.email = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.contactName = this.name;
      data.contactEmail = this.email;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
